@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");

.current {
  color: green;
}

#main_content {
  margin-top: 20px;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 50px;
  /* Margin bottom by footer height */
}

nav.navbar.bg-light {
  background-image: none;
  background-color: white !important;
}

.navbar-light .navbar-brand {
  color: #3399f3 !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  /* Set the fixed height of the footer here */
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bangers", cursive;
  color: #187bcd;
}

.card h1,
.card h2,
.card h3,
.card h4,
.card h5,
.card h6 {
  color: #187bcd;
}
